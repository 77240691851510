import React, { ReactElement } from "react";
import { GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useWebTranslation } from "../translations";
import { ContentfulHeaderProps } from "../contentful/getContentfulHeader";
import { withTranslationAndContentfulStaticProps } from "../contentful/withTranslationAndContentfulStaticProps";
import { ErrorPage } from "../components/PageTemplates/ErrorPage";

function NotFound({
  navigationItems,
  siteLanguages,
}: ContentfulHeaderProps): ReactElement {
  const { t } = useWebTranslation();
  const router = useRouter();

  return (
    <ErrorPage
      navigationItems={navigationItems}
      siteLanguages={siteLanguages}
      metaTitle={t("404.meta.title")}
      metaDescription={t("404.meta.description")}
      title={t("404.title")}
      buttonText={t("404.button")}
      buttonOnPress={() => router.replace(router.locale ?? "")}
      imgSrc="/images/error-404.svg"
    />
  );
}

export default NotFound;

export const getStaticProps: GetStaticProps =
  withTranslationAndContentfulStaticProps();
